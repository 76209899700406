import RecentIcon from '../icons/RecentIcon';
interface Props {
  recentSearch: string;
  onClick: (search: string) => void;
}
const RecentSearches = ({
  recentSearch,
  onClick
}: Props) => {
  return <div className="flex flex-row m-3 cursor-pointer" onClick={() => onClick(recentSearch)} data-sentry-component="RecentSearches" data-sentry-source-file="RecentSearches.tsx">
      <RecentIcon style={{
      marginTop: '3px'
    }} data-sentry-element="RecentIcon" data-sentry-source-file="RecentSearches.tsx" />
      <p className="ml-3 text-lg text-[#5A6066]">{recentSearch}</p>
    </div>;
};
export default RecentSearches;