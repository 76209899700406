import styles from '@/styles/HideScrollbar.module.css';
interface Props {
  count: number;
  sectionId: number;
  isVertical: boolean;
}
const OneXListLoading = ({
  count,
  sectionId,
  isVertical
}: Props) => <section className="animate-pulse" data-sentry-component="OneXListLoading" data-sentry-source-file="OneXListLoading.tsx">
    <header className="flex justify-between items-center mx-4">
      <div className="bg-neutral-10 h-6 w-44 rounded-lg" />
      <div>
        <div className="w-11 h-5 rounded-lg bg-neutral-10 inline-block" />
        <div className="w-5 h-5 rounded-full bg-neutral-10 ml-0.5 inline-block" />
      </div>
    </header>
    <div className={`${isVertical ? 'grid grid-cols-3 md:flex md:flex-wrap gap-4 md:gap-12 items-center' : 'overflow-y-scroll space-x-4 md:space-x-12 flex ' + styles.scroll} px-4 mt-6`}>
      {Array(count).fill(null).map((_, index) => <section className="p-1" key={`${sectionId}-${index}`}>
            <div className="h-20 w-20 bg-neutral-10 rounded-full" />
            <div className="h-5 w-20 bg-neutral-10 rounded-lg mt-1" />
          </section>)}
    </div>
  </section>;
export default OneXListLoading;