import { WEBSITE_URL } from '@/constants/common';
import FBMetaTags from './FBMetaTags';
import TwitterMetaTags from './TwitterMetaTags';
interface Props {
  image?: string | null;
  video?: string;
  title?: string;
  description?: string;
  url?: string;
  type?: string;
}
const SocialMetaTags = ({
  image,
  video,
  title,
  description,
  url,
  type
}: Props) => <>
    <FBMetaTags image={image} video={video} title={title} description={description} url={`${WEBSITE_URL}/${url}`} type={type} data-sentry-element="FBMetaTags" data-sentry-source-file="SocialMetaTags.tsx" />
    <TwitterMetaTags image={image} video={video} title={title} description={description} data-sentry-element="TwitterMetaTags" data-sentry-source-file="SocialMetaTags.tsx" />
  </>;
export default SocialMetaTags;