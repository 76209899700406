// Disabling explicit any since the typeof speech recognition class is any
/* eslint-disable @typescript-eslint/no-explicit-any */
import { DEFAULT_LANG } from '@dehaat/kisan-app-bl/constants/common'
import { useCallback, useEffect, useRef, useState } from 'react'

const useSpeechRecognition = (
  setSpeechResult: (result: string) => void,
  lang = DEFAULT_LANG
) => {
  const [isSpeechRecognitionSupported, setISSpeechRecognitionSupported] =
    useState(false)
  const [isListening, setIsListening] = useState(false)
  const speechRecognitionRef = useRef<any>()
  const timerRef = useRef<number>()

  const stopListening = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
    speechRecognitionRef.current.stop()
    setIsListening(false)
  }, [])

  const onStartListening = useCallback(() => {
    if (speechRecognitionRef.current && !isListening) {
      setIsListening(true)
      speechRecognitionRef.current.start()
      timerRef.current = window.setTimeout(stopListening, 5000)
    }
  }, [stopListening, isListening])

  const onResult = useCallback(
    (event: { results: { transcript: string; confidence: number }[][] }) => {
      const result = event.results[0][0].transcript
      setSpeechResult(result)
    },
    [setSpeechResult]
  )

  const onNoMatch = useCallback(() => {
    setSpeechResult('')
  }, [setSpeechResult])

  const onSpeechEnd = useCallback(() => {
    if (speechRecognitionRef.current) {
      stopListening()
    }
  }, [stopListening])

  useEffect(() => {
    const win = window as any
    const SpeechRecognition =
      win.SpeechRecognition || win.webkitSpeechRecognition
    if (SpeechRecognition) {
      speechRecognitionRef.current = new SpeechRecognition()
      speechRecognitionRef.current.lang = lang
      setISSpeechRecognitionSupported(SpeechRecognition != null)
    }
    return () => {
      if (speechRecognitionRef.current) {
        speechRecognitionRef.current.abort()
      }
    }
  }, [lang])

  useEffect(() => {
    if (isSpeechRecognitionSupported && speechRecognitionRef.current) {
      speechRecognitionRef.current.addEventListener('result', onResult)
      speechRecognitionRef.current.addEventListener('speechend', onSpeechEnd)
      speechRecognitionRef.current.addEventListener('nomatch', onNoMatch)
      speechRecognitionRef.current.addEventListener('error', onNoMatch)
    }

    return () => {
      if (speechRecognitionRef.current) {
        speechRecognitionRef.current.removeEventListener('result', onResult)
        speechRecognitionRef.current.removeEventListener(
          'speechend',
          onSpeechEnd
        )
        speechRecognitionRef.current.removeEventListener('nomatch', onNoMatch)
        speechRecognitionRef.current.removeEventListener('error', onNoMatch)
      }
    }
  }, [isSpeechRecognitionSupported, onNoMatch, onResult, onSpeechEnd])

  return {
    onStartListening,
    isListening,
    isSpeechRecognitionSupported,
    stopListening,
  }
}

export default useSpeechRecognition
