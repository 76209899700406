import { COLORS } from '@dehaat/dds';
import { GridItem as GridItemType, ISection } from '@dehaat/kisan-app-bl/models/Feed';
import { Vendor } from '@dehaat/kisan-app-bl/models/Vendor';
import Image from 'next/image';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';
import { COOKIE_MAX_AGE_1YEAR, CURRENT_VENDOR_KEY, VENDOR_ID_COOKIE_NAME } from '@/constants/common';
import { useAppDispatch } from '@/hooks/reduxHooks';
import vendorImage from '@/images/vendor.webp';
import { setCurrentVendor } from '@/store/features/locationSlice';
import TruncateStyle from '@/styles/TruncateContent.module.css';
import { replaceUrlHost, setLocalStorageKey } from '@/utils/helper';
import Header from './SectionHeader';
const GridItem = ({
  imgUrl,
  name = '',
  style,
  vendor,
  gridBgColor,
  textBgColor
}: {
  imgUrl: string;
  name: string;
  style?: object;
  vendor: Vendor;
  gridBgColor: string;
  textBgColor: string;
}) => {
  const [imgSrc, setImgSrc] = useState(imgUrl || null);
  const dispatch = useAppDispatch();
  const handeClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    document.cookie = `${VENDOR_ID_COOKIE_NAME}=${vendor.id}; max-age=${COOKIE_MAX_AGE_1YEAR}; path=/`;
    setLocalStorageKey(CURRENT_VENDOR_KEY, vendor);
    dispatch(setCurrentVendor(vendor));
  };
  return <div style={{
    background: gridBgColor,
    ...style
  }} className="bg-gray-10 w-[30%] md:w-[22.5%] lg:w-[15%] xl:w-[15%] rounded-lg mb-5 flex flex-col items-center border max-w-[200px] cursor-pointer" onClick={handeClick} data-sentry-component="GridItem" data-sentry-source-file="VendorList.tsx">
      <div className="p-3 lg:p-4">
        <div className={`w-20 h-20 lg:h-[120px] lg:w-[120px] rounded-lg overflow-hidden relative flex items-center justify-center`}>
          {imgSrc || vendorImage ? <Image src={imgSrc || vendorImage} alt={name} fill className="object-contain" onError={() => setImgSrc(null)} /> : null}
        </div>
      </div>
      <div className="flex items-center justify-center rounded-b-lg p-2 lg:px-3 w-full flex-1" style={{
      background: textBgColor
    }}>
        <p className={`text-center font-semibold text-neutral-90 text-sm lg:text-base ${TruncateStyle.truncate_after_two_lines}`}>
          {name}
        </p>
      </div>
    </div>;
};
interface Props {
  vendors: Vendor[];
  metaData: ISection<GridItemType[]>;
}
const VendorList = ({
  vendors,
  metaData
}: Props) => {
  const {
    t
  } = useTranslation('main');
  const vendorListData = vendors?.filter((_, index) => index < 6);
  const title = metaData.metadata.app_display_title || 'Shop By Seller';
  const titleColor = metaData.metadata.child_config?.title_color || '#171B1F';
  return <section style={{
    background: metaData.metadata.secondary_bg || 'white'
  }} className={`w-full md:max-md lg:max-lg py-4 mx-auto flex flex-col px-4 lg:rounded-xl`} data-sentry-component="VendorList" data-sentry-source-file="VendorList.tsx">
      <Header title={title} titleColor={titleColor} subtitle={metaData.metadata.h2 || undefined} data-sentry-element="Header" data-sentry-source-file="VendorList.tsx" />
      <div className="flex flex-wrap overflow-x-hidden scroll-smooth gap-[5%] md:gap-[3.33%] lg:gap-[2%] xl:gap-[2%] pt-4 lg:pt-6 xl:pt-8 justify-center">
        {vendorListData.map(item => <GridItem key={item.id} imgUrl={''} name={item.company_name} vendor={item} gridBgColor={metaData.metadata.child_config?.card_bg_color || '#F5F8FA'} textBgColor={metaData.metadata.child_config?.label_bg_color || '#FFF3C2'} />)}
      </div>
      {metaData.metadata.show_all && <Link style={{
      backgroundColor: metaData.metadata.child_config?.show_all_bg_color || 'white',
      color: metaData.metadata.child_config?.show_all_text_color || COLORS.primary100
    }} className={`flex justify-center px-12 py-2.5 mt-2 rounded-lg max-w-[350px] m-auto border border-neutral-20 w-full`} href={metaData.metadata.deep_link ? replaceUrlHost(metaData.metadata.deep_link) : '/dcs'}>
          <p className="text-center font-nato-semibold">
            {metaData.metadata.show_all_text || t('view_all')}
          </p>
        </Link>}
    </section>;
};
export default VendorList;