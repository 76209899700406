import Head from 'next/head';
import { WEBSITE_NAME } from '@/constants/common';
import { getThumbnailUrl } from '@/utils/helper';
interface Props {
  title?: string;
  description?: string;
  url?: string;
  image?: string | null;
  video?: string;
  type?: string;
}
const FBMetaTags = ({
  title,
  description,
  image = '/assets/images/login-chacha.webp',
  url,
  video,
  type = 'website'
}: Props) => <Head data-sentry-element="Head" data-sentry-component="FBMetaTags" data-sentry-source-file="FBMetaTags.tsx">
    <meta property="og:title" key="og-title" content={title} data-sentry-element="meta" data-sentry-source-file="FBMetaTags.tsx" />
    <meta property="og:site_name" content={WEBSITE_NAME} data-sentry-element="meta" data-sentry-source-file="FBMetaTags.tsx" />
    <meta property="og:url" content={url} key="og-url" data-sentry-element="meta" data-sentry-source-file="FBMetaTags.tsx" />
    {description ? <meta property="og:description" content={description} /> : null}
    <meta property="og:type" content={type} data-sentry-element="meta" data-sentry-source-file="FBMetaTags.tsx" />
    {image ? <meta property="og:image" content={image} /> : null}
    {video ? <>
        <meta property="og:image" content={getThumbnailUrl(video)} />
        <meta property="og:video" content={video} />
      </> : null}
  </Head>;
export default FBMetaTags;