interface Props {
  title: string;
  subtitle?: string;
  titleColor?: string;
  className?: string;
}
const Header = ({
  title,
  titleColor,
  subtitle,
  className = ''
}: Props) => {
  return <header className={`flex flex-col justify-between items-center mx-4 ${className}`} data-sentry-component="Header" data-sentry-source-file="SectionHeader.tsx">
      <h5 className="px-4 text-xl lg:text-2xl text-center" style={{
      color: titleColor || ''
    }}>
        {title}
      </h5>

      {subtitle && <p className="text-center text-neutral-90 mt-1">{subtitle}</p>}
      <hr className={`border-[#C5CED6] border-solid w-24 mt-3 border rounded-lg`} />
    </header>;
};
export default Header;