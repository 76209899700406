import styles from '@/styles/HideScrollbar.module.css';
interface Props {
  sectionId: number;
  count: number;
}
const ProductOneXHorizontalListLoading = ({
  count,
  sectionId
}: Props) => <section className="animate-pulse" data-sentry-component="ProductOneXHorizontalListLoading" data-sentry-source-file="ProductOneXHorizontalListLoading.tsx">
    <header className="flex justify-between items-center mx-4">
      <div className="bg-neutral-10 h-6 w-44 rounded-lg" />
      <div>
        <div className="w-11 h-5 rounded-lg bg-neutral-10 inline-block" />
        <div className="w-5 h-5 rounded-full bg-neutral-10 ml-0.5 inline-block" />
      </div>
    </header>
    <div className={`overflow-y-scroll ${styles.scroll}`}>
      <div className={`w-fit flex mx-4 mt-6 border border-neutral-10 rounded-lg shadow-[0_0_10px_rgba(0,0,0,0.05)]`}>
        {Array(count).fill(null).map((_, index) => <div key={`${sectionId} - ${index}`} className="last:border-r-none border-r border-neutral-10">
              <div className="px-2 pt-8 pb-6 flex items-center min-w-[230px] overflow-hidden">
                <div className="w-20 h-20 bg-neutral-10 rounded-lg" />
                <section className="ml-2">
                  <div className="h-4 w-24 rounded-md bg-neutral-10" />
                  <div className="h-4 w-14 rounded-md mt-1 bg-neutral-10" />
                  <div className="h-4 w-10 rounded-md mt-2.5 bg-neutral-10" />
                  <div className="h-5 w-10 rounded-md mt-3 bg-neutral-10" />
                  <div className="h-8 w-18 rounded-lg mt-3 bg-neutral-10" />
                </section>
              </div>
            </div>)}
      </div>
    </div>
  </section>;
export default ProductOneXHorizontalListLoading;