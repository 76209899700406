import { HorizontalListItem, ISection } from '@dehaat/kisan-app-bl/models/Feed';
import Image from 'next/image';
interface Props {
  metaData: ISection<HorizontalListItem[]>;
}
const HorizontalList = ({
  metaData
}: Props) => {
  const list = metaData.data;
  return <section className="w-full flex px-4 lg:px-8 py-3 justify-evenly gap-4" style={{
    background: '#F5F8FA'
  }} data-sentry-component="HorizontalList" data-sentry-source-file="HorizontalList.tsx">
      {list.map((item, index) => <div key={index} className="flex flex-col lg:flex-row items-center">
          {item.image_url && <Image src={item.image_url} alt="" width={20} height={20} className="object-contain w-10 h-10 max-w-10" />}
          <div className="mt-1 lg:mt-0 lg:ml-2">
            <p className="text-xs font-semibold text-center lg:text-start">
              {item.name}
            </p>
            <p className="text-xs text-neutral-70 mt-[1px] text-center lg:text-start">
              {item.sub_title}
            </p>
          </div>
        </div>)}
    </section>;
};
export default HorizontalList;