import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { NAVIGATION_FROM_APP, NAVIGATION_FROM_APP_CODE } from '@/constants/common';
import useIsMobile from '@/hooks/useIsMobile';
import dehaatLogo from '@/images/dehaat-logo.webp';
import LanguageIcon from '@/images/language.webp';
import { getCookieValue, isDigiAcreUser } from '@/utils/helper';
import AppBackButton from './AppBackButton';
import CartButton from './cart/MyCart';
import CurrentLocation from './navigation/CurrentLocation';
import Profile from './navigation/Profile';
interface Props {
  containerClassname?: string;
  showCart?: boolean;
}
const MainHeader = ({
  containerClassname = '',
  showCart = false
}: Props) => {
  const {
    asPath
  } = useRouter();
  const {
    isMobile,
    isWindowLoading
  } = useIsMobile();
  const isDigiacre = isDigiAcreUser();
  const fromApp = Boolean(getCookieValue(NAVIGATION_FROM_APP)) || Boolean(getCookieValue(NAVIGATION_FROM_APP_CODE));
  return <header className={`bg-white p-4 w-full ${containerClassname}`} data-sentry-component="MainHeader" data-sentry-source-file="MainHeader.tsx">
      <nav className="flex items-center justify-between">
        <div className="flex justify-between items-center gap-3">
          {isDigiacre && <AppBackButton />}
          {fromApp ? <div className="h-12 w-12 flex justify-between bg-primary-10 px-1 py-[14px] rounded-xl">
              <Image alt="Dehaat-logo" src={dehaatLogo} className="w-10 h-5" />
            </div> : <Link href="/" className="h-12 w-12 flex justify-between bg-primary-10 px-1 py-[14px] rounded-xl">
              <Image alt="Dehaat-logo" src={dehaatLogo} className="w-10 h-5" />
            </Link>}
          <CurrentLocation allowNavigation data-sentry-element="CurrentLocation" data-sentry-source-file="MainHeader.tsx" />
        </div>
        <div className="flex gap-2">
          <Link href={`/language?redirect_uri=${asPath}`} aria-label="Click here to select language" data-sentry-element="Link" data-sentry-source-file="MainHeader.tsx">
            <Image alt="Select Language" src={LanguageIcon} width={40} height={40} data-sentry-element="Image" data-sentry-source-file="MainHeader.tsx" />
          </Link>
          {showCart && !isWindowLoading && !isMobile && <CartButton />}
          {!isDigiacre && !fromApp && <Profile />}
        </div>
      </nav>
    </header>;
};
export default MainHeader;