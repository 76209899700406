import { FeedType } from '@dehaat/kisan-app-bl/models/Feed';
import Image from 'next/image';
import Link from 'next/link';
import { useContext, useEffect, useMemo, useState } from 'react';
import { AVAILABLE_STOCK } from '@/constants/common';
import { CartContext } from '@/context/CartProvider';
import { Product, Product2, ProductVariant } from '@/models/Product';
import styles from '@/styles/HideScrollbar.module.css';
import truncateStyles from '@/styles/TruncateContent.module.css';
import { ProductListTypeEnum } from '@/utils/analytics/types';
import { formatProductData } from '@/utils/cart';
import { isArray } from '@/utils/helper';
import transformProductInventorySet from '@/utils/helpers/transformProductInventorySet';
import ProductIcon from '../icons/ProductIcon';
import ProductPrice from '../products/ProductPrice';
import AddToCart from './AddToCart';
import Header from './SectionHeader';
interface Props {
  metaData: FeedType;
}
interface ProductCardProps {
  product: Product;
  position: number;
}
const getActiveVariants = (variants: ProductVariant[], listType?: ProductListTypeEnum) => {
  return variants.filter(variant => {
    if (listType === ProductListTypeEnum.SEARCH) {
      return isArray(variant.inventory_set);
    }
    return variant.active && isArray(variant.inventory_set);
  });
};
const ProductShorts = ({
  metaData
}: Props) => {
  const products = metaData.data;
  return <section className="" data-sentry-component="ProductShorts" data-sentry-source-file="ProductShorts.tsx">
      <Header title="Shop Product: Watch, Explore & Buy" data-sentry-element="Header" data-sentry-source-file="ProductShorts.tsx" />
      <div className={`overflow-y-scroll space-x-4 md:space-x-6 flex px-4 mt-4 ${styles.scroll}`}>
        {products.map((item, index) => {
        function isProduct2(item: any): item is Product2 {
          return 'product_variants' in item;
        }
        if (!isProduct2(item)) {
          return;
        }
        const product = transformProductInventorySet(item);
        return <ProductCard key={index} product={product} position={index} />;
      })}
      </div>
    </section>;
};
const ProductCard = ({
  product
}: ProductCardProps) => {
  const [activeVariants, setActiveVariants] = useState<ProductVariant[]>([]);
  const [isUnavailableAtVendor, setIsUnavailableAtVendor] = useState(false);
  const {
    cartItems
  } = useContext(CartContext);
  const StockInSS = useMemo(() => sessionStorage?.getItem(AVAILABLE_STOCK),
  // We need updated stock details everything quantity changes for any product
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [cartItems]);
  let inventorySet = null;
  let actualPrice = 0;
  let discountedPrice = 0;
  const productSlug = product?.slug?.slug;
  const productGif = product?.product_media || '';
  const imageURl = product.image_url;
  const availableStock = StockInSS ? JSON.parse(StockInSS) : undefined;
  const isOutOfStock = activeVariants.length === 0 || isUnavailableAtVendor;
  useEffect(() => {
    if (isArray(product.product_variants)) {
      const formatedProduct = formatProductData(product);
      setActiveVariants(getActiveVariants(formatedProduct.product_variants, ProductListTypeEnum.PRODUCT));
    }
  }, [product, StockInSS]);
  useEffect(() => {
    if (availableStock && availableStock[activeVariants[0]?.id] === 0) {
      setIsUnavailableAtVendor(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeVariants]);
  if (isArray(activeVariants)) {
    inventorySet = activeVariants[0].inventory_set[0];
    if (inventorySet) {
      actualPrice = Number(inventorySet.price);
      discountedPrice = actualPrice - Number(inventorySet.discount);
    }
  }
  return <div className="bg-gray-20 rounded-xl min-w-[210px] w-[210px]" data-sentry-component="ProductCard" data-sentry-source-file="ProductShorts.tsx">
      <Link href={`/product/${encodeURIComponent(productSlug)}?ps=1`} className={`block`} data-sentry-element="Link" data-sentry-source-file="ProductShorts.tsx">
        {productGif && <Image src={productGif} alt={`${product.name} Benefits`} width={480} height={854} className="inline-block mr-2 rounded-lg w-56 aspect-[9/16] object-cover" unoptimized />}
      </Link>
      <div className="p-2">
        <div className="flex">
          <div className={`aspect-square p-2 shrink-0 grow-0 relative flex items-center justify-center w-16 h-16 rounded-lg bg-white`}>
            {imageURl ? <Image src={imageURl} alt={product.name} width={80} height={80} className={`object-contain`} style={{
            width: 'auto',
            height: '100%'
          }} /> : <ProductIcon />}
          </div>
          <div className="ml-2">
            <p className={`text-sm font-semibold ${truncateStyles.truncate_after_two_lines} h-[40px]`}>
              {product.name}
            </p>
            {isOutOfStock ? null : <ProductPrice actualPrice={actualPrice} discountedPrice={discountedPrice} />}
          </div>
        </div>
        <AddToCart productVariant={activeVariants[0]} listType={ProductListTypeEnum.PRODUCT} isOutOfStock={isOutOfStock} activeVariants={activeVariants} data-sentry-element="AddToCart" data-sentry-source-file="ProductShorts.tsx" />
      </div>
    </div>;
};
export default ProductShorts;