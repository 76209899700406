import Head from 'next/head';
import { ReactNode } from 'react';
import { APP_NAME } from '@/constants/common';
import { getThumbnailUrl } from '@/utils/helper';
interface Props {
  title?: string;
  description?: string;
  image?: string | null;
  video?: string;
}
const TwitterMetaTags = ({
  title,
  description,
  image = '/assets/images/login-chacha.webp',
  video
}: Props) => {
  let imageThumbnailMeta: ReactNode = <></>;
  let videoMeta: ReactNode = <></>;
  if (video) {
    imageThumbnailMeta = <meta property="twitter:image" content={getThumbnailUrl(video)} key="twitter-image" />;
    videoMeta = <meta property="twitter:video" content={video} key="twitter-video" />;
  }
  return <Head data-sentry-element="Head" data-sentry-component="TwitterMetaTags" data-sentry-source-file="TwitterMetaTags.tsx">
      <meta name="twitter:card" content="app" data-sentry-element="meta" data-sentry-source-file="TwitterMetaTags.tsx" />
      <meta name="twitter:site" content="@DeHaatTM" data-sentry-element="meta" data-sentry-source-file="TwitterMetaTags.tsx" />
      <meta name="twitter:title" content={title} data-sentry-element="meta" data-sentry-source-file="TwitterMetaTags.tsx" />
      {description ? <meta name="twitter:description" content={description} /> : null}
      <meta name="twitter:app:name:googleplay" content={APP_NAME} data-sentry-element="meta" data-sentry-source-file="TwitterMetaTags.tsx" />
      <meta name="twitter:app:id:googleplay" content="app.intspvt.com.farmer" data-sentry-element="meta" data-sentry-source-file="TwitterMetaTags.tsx" />
      <meta name="twitter:app:url:googleplay" content="https://play.google.com/store/apps/details?id=app.intspvt.com.farmer" data-sentry-element="meta" data-sentry-source-file="TwitterMetaTags.tsx" />
      <meta name="twitter:app:country" content="India" data-sentry-element="meta" data-sentry-source-file="TwitterMetaTags.tsx" />
      {image ? <meta property="twitter:image" content={image} /> : null}
      {videoMeta}
      {imageThumbnailMeta}
    </Head>;
};
export default TwitterMetaTags;