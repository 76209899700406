import {
  Brand,
  Product,
  Product2,
  ProductVariant,
  ProductVariant2,
  ProductVariantInventorySet,
} from '@/models/Product'

const transformProductInventorySet = (product: Product2): Product => {
  const updatedVariants: ProductVariant[] =
    product.product_variants.map((variant: ProductVariant2): ProductVariant => {
      const inventory_set: ProductVariantInventorySet[] = variant.vendor_quotes
      return {
        ...variant,
        id: variant.id.toString(),
        inventory_set,
        insuranceData: {
          hasInsurance: variant.insurance_data.has_insurance,
          label: variant.insurance_data.label,
        },
      }
    }) || []
  let brand = (product.brand as Brand) || null
  if (typeof product.brand == 'string') {
    brand = {
      name: product.brand as string,
      id: product.brand,
      app_display_name: product.brand as string,
    }
  }

  return {
    ...product,
    product_variants: updatedVariants,
    slug: product.slug_info || { slug: '' },
    brand,
    insuranceData: updatedVariants[0]?.insuranceData,
  }
}

export default transformProductInventorySet
