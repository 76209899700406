import getFeedMetaData from '@dehaat/kisan-app-bl/api/getFeedMetaData';
import { DEFAULT_LANG } from '@dehaat/kisan-app-bl/constants/common';
import { CustomFeed, FeedType, ViewType } from '@dehaat/kisan-app-bl/models/Feed';
import dynamic from 'next/dynamic';
import { useContext, useEffect, useState } from 'react';
import { CART_TYPE } from '@/constants/cart';
import { CartContext } from '@/context/CartProvider';
import { useAppSelector } from '@/hooks/reduxHooks';
import { selectCurrentVendorId, selectLatLng, selectLoadingHyperlocalData, selectOndcVendorId, selectVendorsList } from '@/store/features/locationSlice';
import { isArray } from '@/utils/helper';
import HorizontalList from '../homeV2/HorizontalList';
import ProductShorts from '../homeV2/ProductShorts';
import VendorList from '../homeV2/VendorList';
import OneXListLoading from './filterOneX/OneXListLoading';
import ProductOneXHorizontalListLoading from './productOneX/ProductOneXHorizontalListLoading';
const BannerCarousel = dynamic(() => import('./BannerCarousel'));
const BannerCarouselV2 = dynamic(() => import('../homeV2/BannerCarouselV2'));
const CouponBanner = dynamic(() => import('./CouponBanner'));
const ProductScroll2x = dynamic(() => import('./ProductScrollV2'));
const ProductBannerGrid = dynamic(() => import('./ProductBannerGrid'));
const ProductBannerGridV2 = dynamic(() => import('../homeV2/ProductBannerGridV2'));
const Scrollable1XDesktopGrid = dynamic(() => import('../homeV2/Scrollable1XDesktopGrid'));
const OneXList = dynamic(() => import('./filterOneX/OneXList'));
const Grid = dynamic(() => import('./Grid'));
const GridV2 = dynamic(() => import('../homeV2/GridV2'));
const ProductOneXHorizontalList = dynamic(() => import('./productOneX/ProductOneXHorizontalList'));
const ScrollableList2X = dynamic(() => import('./ScrollableList2X'));
const SimpleBannerWithCTAList = dynamic(() => import('./SimpleBannerWithCTAList'));
interface Props {
  feedList: CustomFeed[];
  locale?: string;
}
const FeedManager = ({
  feedList,
  locale = DEFAULT_LANG
}: Props) => {
  const {
    cartType
  } = useContext(CartContext);
  const [feedMetaData, setFeedMetaData] = useState<FeedType[]>([]);
  const isHyperlocalDataLoading = useAppSelector(selectLoadingHyperlocalData);
  const isONDC = cartType === CART_TYPE.ONDC;
  const [loading, setLoading] = useState(true);
  const latLng = useAppSelector(selectLatLng);
  const vendorId = useAppSelector(isONDC ? selectOndcVendorId : selectCurrentVendorId);
  const sectionIds = feedList.map(feedItem => feedItem.section_id).join(',');
  const vendorList = useAppSelector(selectVendorsList);
  useEffect(() => {
    const getSectionMetaData = async () => {
      setLoading(true);
      const response = await getFeedMetaData(sectionIds, vendorId ? Number(vendorId) : undefined, undefined, latLng, locale, undefined, isONDC);
      setFeedMetaData(response);
      setLoading(false);
    };
    if (!isHyperlocalDataLoading) getSectionMetaData();
  }, [latLng, vendorId, isHyperlocalDataLoading, isONDC, locale, sectionIds]);
  return <section className="space-y-5 mt-2" data-sentry-component="FeedManager" data-sentry-source-file="FeedManager.tsx">
      {feedList.map(({
      section_id,
      view_type
    }) => {
      if (loading) {
        switch ((view_type as ViewType | 'filter_vertical_1x')) {
          case 'filter_scroll_1x':
            return <OneXListLoading key={section_id} count={5} sectionId={section_id} isVertical={false} />;
          case 'filter_vertical_1x':
            return <OneXListLoading key={section_id} count={5} sectionId={section_id} isVertical />;
          case 'product_scroll_1x':
            return <ProductOneXHorizontalListLoading count={5} key={section_id} sectionId={section_id} />;
          default:
            return <div key={section_id} className="animate-pulse bg-neutral-20 h-32 mx-4 rounded-lg" />;
        }
      }
      const metaData = feedMetaData.find(metaData => metaData.id === section_id);
      if (!metaData || !isArray(metaData.data)) {
        return null;
      }
      const props = {
        loading,
        metaData,
        sectionId: section_id
      };
      switch ((view_type as ViewType | 'filter_vertical_1x')) {
        case 'filter_scroll_1x':
          return <OneXList {...props} key={section_id} />;
        case 'filter_vertical_1x':
          return <OneXList {...props} key={section_id} isVertical />;
        case 'product_scroll_1x':
          return <ProductOneXHorizontalList {...props} key={section_id} />;
        case 'scroll_2x':
          return <ScrollableList2X {...props} key={section_id} />;
        case 'simple_banner_with_cta':
          return <SimpleBannerWithCTAList {...props} key={section_id} />;
        case 'simple_banner':
          return <BannerCarousel {...props} key={section_id} />;
        case 'simple_banner_v2':
          return <BannerCarouselV2 {...props} key={section_id} />;
        case 'product_grid':
          return <ProductBannerGrid {...props} key={section_id} />;
        case 'product_grid_v2':
          return <ProductBannerGridV2 {...props} key={section_id} />;
        case 'grid':
          return <Grid {...props} key={section_id} />;
        case 'grid_v2':
          return <GridV2 {...props} key={section_id} />;
        case 'scroll_1x_desktop_grid':
          return <Scrollable1XDesktopGrid {...props} key={section_id} />;
        case 'coupon_banner':
          return <CouponBanner {...props} key={section_id} />;
        case 'product_scroll_1x_v2':
          return <ProductScroll2x {...props} key={section_id} />;
        case 'shorts_products_scroll_1x':
          return <ProductShorts {...props} key={section_id} />;
        case 'vendor_list':
          return <VendorList vendors={vendorList} {...props} key={section_id} />;
        case 'horizontal_list':
          return <HorizontalList {...props} key={section_id} />;
        default:
          return null;
      }
    })}
    </section>;
};
export default FeedManager;